import React from 'react'
import styled from 'styled-components'
import theme from '../../constants/theme'

const ResponsiveTextWrapper = styled.div`
  p {
    a:link, a:visited {
      text-decoration: underline;
    }
    strong {
      position: relative;
      z-index: 0;
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 50%;
        background-color: rgb(252, 241, 19);
        left: 0px;
        bottom: 0px;
        z-index: -1;
      }
    }
  }
  & > * {
    font-size: calc((${props => props.properties.minFontSize + 'px'})  + (${props => props.properties.maxFontSize} - ${props => props.properties.minFontSize}) * ((100vw - 320px) / 1320));
    line-height: calc((${props => props.properties.minLineHeight + 'px'})  + (${props => props.properties.maxLineHeight} - ${props => props.properties.minLineHeight}) * ((100vw - 320px) / 1320));
    letter-spacing: calc((${props => '-' + props.properties.minLetterSpacing + 'px'}) + ((-${props => props.properties.maxLetterSpacing}) + ${props => props.properties.minLetterSpacing}) * ((100vw - 320px) / 1320));
    
    ${props => props.properties.letterSpacing ? `letter-spacing: ${props.properties.letterSpacing}` : ``}
  }

  @media (min-width: 1440px) {
    & > * {
      font-size: ${props => props.properties.maxFontSize + 'px'};
      line-height: ${props => props.properties.maxLineHeight + 'px'};
      letter-spacing: ${props => '-' + props.properties.maxLetterSpacing + 'px'};
    }
  }

  @media (max-width: 374px) {
    & > * {
      font-size: ${props => props.properties.minFontSize + 'px'};
      line-height: ${props => props.properties.minLineHeight + 'px'};
      letter-spacing: ${props => '-' + props.properties.minLetterSpacing + 'px'};
    }
  }
`

export default function ResponsiveText({ children, type }) {
  let properties = {}

  switch (type) {
    case 'extreme':
      properties = theme.responsiveFonts.extreme
      break
    // case 'bigger':
    //   properties = theme.responsiveFonts.bigger
    //   break
    case 'bigger':
      properties = theme.responsiveFonts.bigger
      break
    case 'servizi':
      properties = theme.responsiveFonts.servizi
      break
    case 'big':
      properties = theme.responsiveFonts.big
      break
    case 'medium':
      properties = theme.responsiveFonts.medium
      break
    case 'not_found':
      properties = theme.responsiveFonts.notFound
      break
    case 'normal':
    default:
      properties = theme.responsiveFonts.normal
  }

  return (
    <ResponsiveTextWrapper properties={properties}>{children}</ResponsiveTextWrapper>
  )
}