import React from 'react'
import styled from 'styled-components'
import theme from '../../constants/theme'

const StyledWrapper = styled.div`
  @keyframes slideLeft {
    0% {
      transform: translateX(-100%);
    }

    50% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideRight {
    0% {
      transform: translateX(0);
    }

    50% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(-300%);
    }
  }

  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 100%;
  width: 100vw;
  height: 100vh;
  background-color: ${theme.colorBlack};
  animation: slideLeft 5s ease-in-out;

  .child {
    width: 80%;
    height: 100%;
    background-color: ${theme.colorYellow};
    animation: slideLeft 3s ease-in-out;
  }

  ${props => props.inverted && `
    animation: slideRight 2.5s ease-in-out;
    animation-fill-mode: forwards;

    .child {
      animation: slideRight 4.5s ease-in-out;
      animation-fill-mode: forwards;
    }
  `}

`

export default function PageTransition({ inverted = false }) {
  return (
    <StyledWrapper inverted={inverted}>
      <div className="child"></div>
    </StyledWrapper>
  )
}