import React, { useContext } from 'react'
import styled from 'styled-components'
import { MouseContext } from '../../context/MouseContext'
import useMousePosition from '../../helpers/useMousePosition'

import arrow from '../../images/arrow-spotview.svg'

const CursorWrapper = styled.div`
  #cursor {
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    pointer-events: none;
    will-change: transform;
    /* mix-blend-mode: difference; */
    opacity: 0.9;

    .cursor__circle {
      display: block;
      background: #fcf113;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-top: -50%;
      margin-left: -50%;
      transition: opacity 0.3s cubic-bezier(0.25, 1, 0.5, 1),
            background-color 0.3s cubic-bezier(0.25, 1, 0.5, 1),
            border-color 0.3s cubic-bezier(0.25, 1, 0.5, 1),
            width 0.3s cubic-bezier(0.25, 1, 0.5, 1),
            height 0.3s cubic-bezier(0.25, 1, 0.5, 1);

      &.hidden {
        display: none;
      }
    }

    &.hovered_card {
      mix-blend-mode: difference;

      .cursor__circle {
        width: 128px;
        height: 128px;
        background: #fafafa;

        &:after {
          content: '';
          position: absolute;
          left: -50%;
          top: -50%;
          width: 100%;
          height: 100%;
          color: #fafafa;
          background: url(${props => props.icon});
          background-repeat: no-repeat;
          text-align: center;
          font-size: 100px;
          transform: translate(20%, 0%);
        }
      }
    }

    &.hovered_tab {
      mix-blend-mode: difference;

      .cursor__circle {
        width: 128px;
        height: 128px;
        background: #fafafa;

        &:after {
          content: '+';
          position: absolute;
          left: -50%;
          top: -50%;
          width: 100%;
          height: 100%;
          color: #000a12;
          background-repeat: no-repeat;
          text-align: center;
          font-size: 100px;
          transform: translate(0%, -70%);
        }
      }
    }

    &.hovered_cta {
      mix-blend-mode: difference;

      .cursor__circle {
        width: 128px;
        height: 128px;
      }
    }

    &.hovered_img {
      mix-blend-mode: difference;
    }
  }

  @media (max-width: 1024px) {
    display: none;
  }
`

export default function Cursor() {
  const { cursorType, cursorChangeHandler } = useContext(MouseContext);
  const { x, y } = useMousePosition();

    if (typeof window !== 'undefined') {
      const hoverCtaList = document.querySelectorAll('.hover-cta')
      const hoverTabList = document.querySelectorAll('.hover-tab')
      const hoverCardList = document.querySelectorAll('.hover-card')
      const hoverImgList = document.querySelectorAll('.hover-img')

      const hoveredCtaEvent = {
        handleEvent(e) {
          if (e.type === 'mouseenter') {
            cursorChangeHandler("hovered_cta")
          }
          else if (e.type === 'mouseleave') {
            cursorChangeHandler("")
          }
        }
      }
  
      const hoveredCardEvent = {
        handleEvent(e) {
          if (e.type === 'mouseenter') {
            cursorChangeHandler("hovered_card")
          }
          else if (e.type === 'mouseleave') {
            cursorChangeHandler("")
          }
        }
      }

      const hoveredTabEvent = {
        handleEvent(e) {
          if (e.type === 'mouseenter') {
            cursorChangeHandler("hovered_card")
          }
          else if (e.type === 'mouseleave') {
            cursorChangeHandler("")
          }
        }
      }

      const hoveredImgEvent = {
        handleEvent(e) {
          if (e.type === 'mouseenter') {
            cursorChangeHandler("hovered_img")
          }
          else if (e.type === 'mouseleave') {
            cursorChangeHandler("")
          }
        }
      }
  
      hoverCtaList.forEach(h => {
        h.addEventListener('mouseenter', hoveredCtaEvent)
        h.addEventListener('mouseleave', hoveredCtaEvent)
      })
  
      hoverTabList.forEach(h => {
        h.addEventListener('mouseenter', hoveredTabEvent)
        h.addEventListener('mouseleave', hoveredTabEvent)
      })
      hoverCardList.forEach(h => {
        h.addEventListener('mouseenter', hoveredCardEvent)
        h.addEventListener('mouseleave', hoveredCardEvent)
      })
      hoverImgList.forEach(h => {
        h.addEventListener('mouseenter', hoveredImgEvent)
        h.addEventListener('mouseleave', hoveredImgEvent)
      })
    }

  return (
    <CursorWrapper icon={arrow}>
      <div
        style={{ left: `${x}px`, top: `${y}px` }}
        className={cursorType}
        id="cursor"
      >
        <div className={`cursor__circle  ${!x && !y ? 'hidden' : ''}`}></div>
      </div>
      {/* <div
        className={"dot " + cursorType}
        style={{ left: `${x}px`, top: `${y}px` }}
      ></div> */}
    </CursorWrapper>
  );
}