import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import theme from '../../constants/theme';
import { Fade } from 'react-reveal';
import { DateTime } from 'luxon';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import isLng from '../../helpers/i18n';

const MenuWrapper = styled.div`
  ${(props) =>
    !props.isFooter
      ? `
    background-color: ${theme.colorBlack};
    color: ${theme.colorWhite};
  `
      : `
    background-color: ${theme.colorBlack};
    color: ${theme.colorWhite};
      `}
  height: 100%;
  padding-bottom: 60px;
  .menu-container {
    height: 100%;
    display: block;
    align-items: center;
    position: relative;
    flex-wrap: wrap;
    .flex-cols {
      margin-top: 70px;
      margin-bottom: 60px;
      width: 100%;
      flex: 0 0 100%;
      display: flex;
      flex-wrap: wrap;
      .main-left {
        flex: 0 0 50%;
        width: 50%;
        ol {
          counter-reset: section;
          li {
            counter-increment: exampleCounter;
            &:before {
              content: '0' counter(exampleCounter, decimal) '. ';
              color: #fcf231;
              font-size: 22px;
              min-width: 35px;
              display: inline-block;
              margin-right: 2px;
            }
            a {
              font-family: Syne;
              text-transform: uppercase;
              font-size: 38px;
              font-weight: bold;
              line-height: 35px;
            }
          }
        }
      }
    }
    .right {
      flex: 0 0 25%;
      width: 25%;
      h3.menu-title {
        font-family: Syne;
        font-size: 11px;
        font-weight: 400;
        margin-bottom: 16px;
      }
      .menu-regular {
        li {
          a {
            font-family: Syne;
            font-size: 16px;
            line-height: 21px;
            font-weight: bold;
            text-transform: uppercase;
          }
        }
      }
    }
  }
  ul.menu {
    height: 50%;
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    li {
      flex: 1 0 50%;
      display: flex;
      align-items: center;
      font-family: ${theme.fontNeue};

      span {
        font-size: 14px;
        color: ${theme.colorYellow};
        margin-right: 10px;
      }

      a {
        font-size: 30px;
        font-family: ${theme.fontSyne};
        text-transform: uppercase;

        &:hover {
          color: ${theme.colorYellow};
        }
      }
    }
  }

  .footer-info {
    position: relative;
    left: 0;
    bottom: 20px;
    font-size: 13px;
    display: flex;
    width: 100%;

    & > div {
      flex: 1 0 50%;
    }

    .footer-colophon {
      .colophon-wrapper {
        padding-right: 10px;
        & > span {
          display: inline-block;
          margin-right: 15px;
          a {
            text-decoration: underline;
          }
        }
      }
    }

    .footer-socials {
      ul {
        li {
          display: inline-block;
          font-size: 16px;
          margin-right: 50px;
        }
      }
    }
  }

  .languages {
    align-items: center;
    justify-content: center;

    li {
      a {
        border: 1px solid ${theme.colorWhite};
        background-color: transparent;
        color: ${theme.colorWhite};
        font-size: 1rem;
        margin-left: 0.5rem;
        font-family: '${theme.fontSyne}';
        text-transform: uppercase;
        font-weight: 700;
        line-height: 1.2;
        padding: 5px;
        width: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;

        &.active-lang {
          background-color: ${theme.colorWhite};
          color: ${theme.colorBlack};
        }
      }
    }
  }

  @media (max-width: 1024px) {
    padding-bottom: 30px;
    .menu-container {
      .flex-cols {
        margin-top: 0;
        padding-top: 70px;
        .main-left,
        .right {
          flex: 0 0 100%;
          width: 100%;
          margin-bottom: 20px;
        }
        .main-left {
          margin-bottom: 0;
          ol {
            li {
              a {
                font-size: 24px;
                line-height: 40px;
              }
            }
          }
        }
        .right {
          h3.menu-title {
            margin-bottom: 0;
            margin-top: 50px;
            margin-bottom: 16px;
          }
          ul.menu-regular {
            margin-left: 0;
            li {
              a {
                font-size: 15px;
              }
            }
          }
        }
      }
      ul.menu {
        padding: 0 10%;

        li {
          flex: 1 0 100%;
        }
      }

      .footer-info {
        padding: 0;
        flex-wrap: wrap;

        & > div {
          flex: 1 0 100%;
        }

        .footer-socials {
          order: -1;
          margin-bottom: 25px;
        }

        .footer-colophon {
          .colophon-wrapper {
            padding-right: 0;
            & > span:first-child {
              display: block;
              margin-bottom: 24px;
            }
          }
        }
      }
    }
  }
`;

const MenuImg = styled.div`
  &.in-footer {
    padding-top: 0 !important;
  }
  width: 100%;
  padding-top: 100px;
  div {
    height: 300px;
  }
  img {
    height: 500px;
    width: 100%;
    object-fit: cover;
  }
  @media (max-width: 1280px) {
    padding-top: 85px;
  }
  @media (max-width: 1024px) {
    display: none;
  }
`;

export default function Menu({ opened, isFooter }) {
  const { languages, originalPath, i18n, t } = useI18next();

  const query = useStaticQuery(graphql`
    query MyQuery {
      backend {
        contentMenu {
          altImmagine
          descrizioneImmagine
          immagine {
            id
            width
            url
            imageFile {
              id
              childImageSharp {
                children {
                  id
                }
                fixed {
                  src
                  originalName
                }
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  `);

  return (
    <MenuWrapper isFooter={isFooter}>
      <MenuImg className={isFooter ? `in-footer` : ``}>
        <GatsbyImage
          image={getImage(query.backend.contentMenu.immagine.imageFile)}
          alt={query.backend.contentMenu.altImmagine}
        />
      </MenuImg>
      <div className="container mx-auto">
        <div className={isFooter ? `footer-menu` : `full-screen-section`}>
          <Fade when={opened && !isFooter} delay={1000}>
            <div className="menu-container">
              <div className="flex-cols">
                <ul className="languages flex md:hidden mt-11">
                  {languages.map((lng) => (
                    <li key={lng}>
                      <Link
                        to={originalPath}
                        language={lng}
                        className={isLng(i18n, lng) ? 'active-lang' : undefined}
                      >
                        {lng}
                      </Link>
                    </li>
                  ))}
                </ul>

                <div className="main-left mt-9 md:mt-0">
                  <ol className="menu-large">
                    <li>
                      <Link className="hover-cta" to="/chi-siamo">
                        {t('chiSiamo')}
                      </Link>
                    </li>
                    <li>
                      <Link className="hover-cta" to="/casi-studio">
                        {t('casiStudio')}
                      </Link>
                    </li>
                    <li>
                      <Link className="hover-cta" to="/careers">
                        {t('careers')}
                      </Link>
                    </li>
                    <li>
                      <Link className="hover-cta" to="/contatti">
                        {t('contacts')}
                      </Link>
                    </li>
                  </ol>
                </div>
                <div className="right first">
                  <h3 className="menu-title">{t('services')}</h3>
                  <ul className="menu-regular">
                    <li>
                      <Link
                        className="hover-cta"
                        to="/servizi/experience-design"
                      >
                        {t('experienceDesign')}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="hover-cta"
                        to="/servizi/experience-management"
                      >
                        {t('experienceManagement')}
                      </Link>
                    </li>
                    <li>
                      <Link className="hover-cta" to="/servizi/ecommerce">
                        {t('ecommerce')}
                      </Link>
                    </li>
                    <li>
                      <Link className="hover-cta" to="/servizi/development">
                        {t('development')}
                      </Link>
                    </li>
                    <li>
                      <Link className="hover-cta" to="/servizi/performance">
                        {t('performance')}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="hover-cta"
                        to="/servizi/data-intelligence"
                      >
                        {t('dataIntelligence')}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="right second">
                  <h3 className="menu-title">{t('soluzioniEcommerce')}</h3>
                  <ul className="menu-regular">
                    <li>
                      <Link
                        className="hover-cta"
                        to="/landing/agenzia-shopify-shopify-plus"
                      >
                        {t('agenziaShopifyShopifyPlus')}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="hover-cta"
                        to="/landing/partner-bigcommerce"
                      >
                        {t('partnerBigcommerce')}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="hover-cta"
                        to="/landing/headless-commerce"
                      >
                        {t('headlessCommerce')}
                      </Link>
                    </li>
                    <li>
                      <Link className="hover-cta" to="/landing/agenzia-zendesk">
                        {t('agenziaZendesk')}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="hover-cta"
                        to="/landing/hubspot-agenzia-partner"
                      >
                        {t('hubspotAgenziaPartner')}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="hover-cta"
                        to="/landing/klaviyo-agenzia-partner"
                      >
                        {t('Klaviyo')}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer-info items-end">
                <div className="footer-colophon">
                  <div className="colophon-wrapper">
                    <span>
                      © {DateTime.now().toFormat('yyyy')} SpotView S.r.l. | P.
                      Iva 02263420412
                    </span>
                    <span>
                      <a
                        href="https://www.privacylab.it/informativa.php?20069439282"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Privacy Policy
                      </a>
                    </span>
                    <span>
                      <Link to="/cookies">Cookie Policy</Link>
                    </span>
                    <span>
                      <a href="https://www.linkedin.com/company/spotview">
                        Linkedin
                      </a>
                    </span>
                  </div>
                </div>

                <div className="footer-colophon">
                  <div className="flex items-center justify-end">
                    <ul className="languages flex">
                      {languages.map((lng) => (
                        <li key={lng}>
                          <Link
                            to={originalPath}
                            language={lng}
                            className={
                              isLng(i18n, lng) ? 'active-lang' : undefined
                            }
                          >
                            {lng}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </MenuWrapper>
  );
}
