/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import Helmet from 'react-helmet'
import logo from '../../images/spotview-b.svg'
 
function Seo({ seo, location }) {
  const renderSocialImg = () => seo.Image && seo.Image.src ? location.origin + seo.Image.src : logo
   
  return (
    <Helmet  htmlAttributes={{
          lang: 'it',
      }}>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="keywords" content={seo.keywords} />
      <meta name="image" content={renderSocialImg()} />
      <meta property="og:image" content={renderSocialImg()} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={renderSocialImg()} />
      <meta http-equiv="ScreenOrientation" content="autoRotate:disabled" />
      {seo.noIndex && <meta name="robots" content="NOINDEX,NOFOLLOW" />}
    </Helmet>
  )
}

export default Seo
