import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Menu from './Menu';
import theme from '../../constants/theme';
import usePrevious from '../../helpers/usePrevious';
import logoB from '../../images/spotview-b.svg';
import logoW from '../../images/spotview-w.svg';
import PageTransition from '../animations/PageTransition';
import { Link } from 'gatsby-plugin-react-i18next';

const Wrapper = styled.section`
  position: sticky;
  top: 0;
  z-index: 9;

  .header-wrapper {
    position: relative;
    display: flex;
    justify-content: flex-end;
    height: 96px;

    .logo {
      flex: 1;
      display: flex;
      align-items: center;

      img {
        width: 169px;
      }
    }
    .logomenu {
      img {
        width: 169px;
      }
      display: none;
      &.menu {
        flex: 1;
        display: flex;
        align-items: center;
        position: absolute;
        top: 0px;
        height: 96px;
        left: 0;
        z-index: 999;

        @media (max-width: 1024px) {
          height: 56px;
        }
      }
    }

    .button-menu {
      &:focus {
        outline: 0;
      }
      flex: 0;
      font-size: 13px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      /* cursor: pointer; */
      position: sticky;
      top: 0;
      z-index: 999;

      .menu-icon {
        width: 16px;
        height: 16px;
        background-color: ${theme.colorBlack};
        margin-left: 5px;
        display: inline-block;
      }

      &.is-open {
        color: ${theme.colorWhite};
      }
    }
  }

  @media (max-width: 1024px) {
    .header-wrapper {
      height: 56px;
    }
  }
`;

const MenuWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: -100%;
  transition: all 1.2s ease-in-out;
  /* transition-delay: 1s; */

  ${(props) =>
    props.opened
      ? `
    left: 0;
  `
      : 'left: -100%'}
`;

export default function Header({ white }) {
  const [opened, setOpened] = useState(false);
  const [transition, setTransition] = useState('');

  const prevStatus = usePrevious(opened);

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];

    if (opened) {
      body.style.overflowY = 'hidden';

      if (!prevStatus) {
        setTransition('normal');
      }
    } else {
      body.style.overflowY = 'auto';

      if (prevStatus) {
        setTransition('inverted');
      }
    }
  }, [opened, prevStatus]);

  return (
    <>
      <Wrapper id="header">
        <div className="container mx-auto">
          <div className="header-wrapper">
            <div className="logo" data-scroll-section>
              <Link to="/">
                <img className="logoW" src={logoW} alt="" />
                <img
                  className="logoB"
                  src={logoB}
                  alt="Spotview - Experience First"
                />
              </Link>
            </div>
            <div className={'logomenu' + (opened ? ' menu' : '')}>
              <Link to="/">
                <img src={logoW} alt="Spotview" />
              </Link>
            </div>
            <button
              className={'button-menu hover-cta ' + (opened ? 'is-open' : '')}
              onClick={() => setOpened(!opened)}
            >
              <span className="heading">{opened ? 'Chiudi' : 'Menu'}</span>
              {!opened ? (
                <span className="menu-icon"></span>
              ) : (
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
        <MenuWrapper opened={opened} prevStatus={prevStatus}>
          <Menu opened={opened} prevStatus={prevStatus} />
        </MenuWrapper>
        {transition !== '' && (
          <PageTransition inverted={transition === 'inverted'} />
        )}
      </Wrapper>
    </>
  );
}
