import React from 'react'
import styled from 'styled-components'
import Menu from '../commons/Menu'

const FooterWrapper = styled.section`
`

export default function Footer() {
  return (
    <FooterWrapper data-scroll-section data-scroll data-scroll-id="footerSection" data-scroll-offset="20%,20%">
      <Menu isFooter />
    </FooterWrapper>
  )
}