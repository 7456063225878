import React, { useRef, useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import ScrollContainer from '../animations/ScrollContainer';
import styled from 'styled-components';

import { LocomotiveScrollProvider } from 'react-locomotive-scroll';
import Cursor from './Cursor';
import MouseContextProvider from '../../context/MouseContext';
import Seo from './Seo';
import arrow from '../../images/arrow.png';
import useWindowResize from '../../helpers/useWindowResize';

import { isMobile } from 'react-device-detect';

const RotateLayer = styled.div`
  background: #fff;
  position: absolute;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  p {
    font-size: 30px;
  }

  img {
    max-width: 80px;
    margin-left: 20px;
  }
`;

export default function Layout({
  pageContext,
  children,
  location,
  seo,
  headerWhite,
  disableBackgroundChange,
  isHeaderFullScreen,
}) {
  const containerRef = useRef(null);
  const { width, height } = useWindowResize();
  const [renderRotate, setRenderRotate] = useState(false);

  const pathName = location && location.pathname;

  /**
   * GESTIONE Seo
   */

  const seoProps = {
    title: '',
    description: '',
    keywords: '',
    // image: featuredImage && featuredImage.src
  };

  if (seo) {
    seoProps.title = seo.title;
    seoProps.description = seo.description;
    seoProps.keywords = seo.keywords;
  }

  if (pageContext && pageContext.seorules && pageContext.seorules.length > 0) {
    const seoRule = pageContext.seorules[0] && pageContext.seorules[0].Seo;
    seoProps.title = seoRule.title;
    seoProps.description = seoRule.description;
    seoProps.keywords = seoRule.keywords;
  }

  if (pageContext && pageContext.noIndex) {
    seoProps.noIndex = true;
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (
        !isMobile ||
        (isMobile && window.matchMedia('(orientation: portrait)').matches)
      ) {
        setRenderRotate(false);
      } else {
        setRenderRotate(true);
      }
    }
  }, [width]);

  return (
    <MouseContextProvider>
      <Cursor />
      <Header white={headerWhite} />
      <Seo seo={seoProps} />
      {children}
      {pathName === '/' ? <Footer /> : ''}
    </MouseContextProvider>
  );
}
