const theme = {
  backendUrl: 'https://backend-site-v2.spotview.tech',
  colorBlack: '#000A12',
  colorWhite: '#FAFAFA',
  colorYellow: '#FCF113',
  colorGray: '#9ca5aa',
  fontSyne: 'Syne',
  fontNeue: 'neue-haas-grotesk-display',
  maxWidthDesktop: 1340,
  responsiveFonts: {
    extreme: {
      maxFontSize: 184,
      minFontSize: 48,
      maxLineHeight: 160,
      minLineHeight: 46,
      maxLetterSpacing: 20,
      minLetterSpacing: 5
    },
    bigger: {
      maxFontSize: 92,
      minFontSize: 32,
      maxLineHeight: 82,
      minLineHeight: 30,
      maxLetterSpacing: '-4px',
      minLetterSpacing: '-2px'
    },
    servizi: {
      maxFontSize: 77,
      minFontSize: 42,
      maxLineHeight: 80,
      minLineHeight: 40,
      letterSpacing: '-5px'
    },
    big: {
      maxFontSize: 148,
      minFontSize: 38,
      maxLineHeight: 126,
      minLineHeight: 45,
      letterSpacing: '-5px'
    },
    medium: {
      maxFontSize: 64,
      minFontSize: 28,
      maxLineHeight: 74,
      minLineHeight: 45,
      letterSpacing: 'auto'
    },
    notFound: {
      maxFontSize: 140,
      minFontSize: 75,
      maxLineHeight: 100,
      minLineHeight: 68,
      maxLetterSpacing: 10,
      minLetterSpacing: 5
    },
    normal: {
      maxFontSize: 27,
      minFontSize: 22,
      maxLineHeight: 34,
      minLineHeight: 26,
      letterSpacing: 'auto'
    }
  }
}

export default theme