import React from 'react'
import styled from 'styled-components'
import theme from '../constants/theme'

const BackToTopDiv = styled.div`
    width: 82px;
    height: 82px;
    bottom: 60px;
    @media (max-width: 1024px) {
        width: 45px;
        height: 45px;
        bottom: 20px;
    }
    background-color: ${theme.colorYellow};
    position: fixed;
    right: -140px;
    transition: right ease 0.5s, padding ease 0.5s;
    padding: 0px;
    .arrowDiv{
        overflow: hidden;
        width: 82px;
        height: 82px;
        @media (max-width: 1024px) {
            width: 45px;
            height: 45px;
        }
        margin: 0px;
        position: relative;
        .arrow {
            position: absolute;
            top: 30px;
            left: 30px;
            width: 22px;
            height: 22px;
            color: black !important;
            @media (max-width: 1024px) {
                top: 12px;
                left: 13px;
                width: 18px;
                height: 18px;
            }
            @media (min-width: 1025px) {
                &.exit {
                    animation-name: arrow-out;
                    animation-duration: 0.5s;
                    animation-fill-mode: forwards;
                    animation-timing-function: ease-out;
                }
                &.enter {
                    animation-name: arrow-in;
                    animation-duration: 0.4s;
                    animation-fill-mode: forwards;
                    animation-timing-function: ease-out;
                }
            }
        }    

    }
`



function BackToTop({scroll}) {
    function setArrowClass(className){
        const el = document.getElementById("btt-arrow")
        if(!el.classList.contains(className)){
            el.classList.add(className)
        }
    }
    function removeArrowClass(className){
        const el = document.getElementById("btt-arrow")
        if(el.classList.contains(className)){
            el.classList.remove(className)
        }
    }
    function doExit(){
        removeArrowClass("enter")
        setArrowClass("exit")
    }
    function doEnter(){
        removeArrowClass("exit")
        setArrowClass("enter")
    }
    return (
        <BackToTopDiv onMouseEnter={doExit} onMouseLeave={doEnter} onClick={()=>{/*window.location.reload(); */ scroll.scrollTo("top", {duration:10});}} className="flex items-center justify-center back-to-top">
            <div className="arrowDiv">
                <svg className="arrow" id="btt-arrow" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
                </svg>
            </div>
        </BackToTopDiv>
    )
}

export default BackToTop
